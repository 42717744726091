<template>
	<SelectTradeIn
		v-if="!tradeInItem"
		:heading="heading"
		:sub-heading="subHeading"
		:badge="badge"
		:button="button"
		:image="image"
		:trade-in-url="tradeInUrl"
	/>

	<ShowTradeIn
		v-else
		:heading="heading"
		:sub-heading="subHeading"
		:button="button"
		:badge="badge"
		:phone-data="phoneData"
		@remove="removeTradeInItem"
	/>
</template>

<script setup lang="ts">
import { dynamicContent } from "~/plugins/content-plugin";
import { formatPrice } from "~/helpers/formatting/price";

type TextInpyt = {
	type: "SWAP" | "TRADE_IN" | "TRADE_IN_WITH_BONUS" | "SELECT";
	textType: "HEADING" | "SUB_HEADING" | "BUTTON_TEXT" | "BADGE_TEXT";
	text: string;
};

const props = defineProps({
	texts: {
		type: Array as PropType<TextInpyt[]>,
		default: () => [
			{
				type: "SELECT",
				textType: "HEADING",
				text: "Få penger for din gamle mobil",
			},
			{
				type: "SELECT",
				textType: "BUTTON_TEXT",
				text: "Sjekk hvor mye du kan få",
			},
			{
				type: "SELECT",
				textType: "BADGE_TEXT",
				text: "Innbytte",
			},
			{
				type: "SWAP",
				textType: "BADGE_TEXT",
				text: "SWAP-IN",
			},
			{
				type: "SWAP",
				textType: "BUTTON_TEXT",
				text: "Fjern",
			},
			{
				type: "TRADE_IN",
				textType: "SUB_HEADING",
				text: "Beløpet får du utbetalt ut på konto.",
			},
			{
				type: "TRADE_IN",
				textType: "HEADING",
				text: 'Din estimerte verdi: <span class="variable"> estimate </span>',
			},
			{
				type: "TRADE_IN",
				textType: "BUTTON_TEXT",
				text: "Fjern",
			},
			{
				type: "TRADE_IN",
				textType: "BADGE_TEXT",
				text: "Innbytte",
			},
			{
				type: "TRADE_IN_WITH_BONUS",
				textType: "BADGE_TEXT",
				text: "Innbytte med bonus",
			},
			{
				type: "TRADE_IN_WITH_BONUS",
				textType: "HEADING",
				text: 'Din estimerte verdi: <span class="variable"> estimate </span>',
			},
			{
				type: "TRADE_IN_WITH_BONUS",
				textType: "SUB_HEADING",
				text: "Beløpet får du utbetalt ut på konto.",
			},
			{
				type: "TRADE_IN_WITH_BONUS",
				textType: "BUTTON_TEXT",
				text: "Fjern",
			},
		],
	},
	image: {
		type: Object,
		default: () => ({
			image_md: { src: "/binaries/ecommerce/bilder-ikoner/innbytte.svg?w=100&h=100" },
			alt: "Innbytte",
		}),
	},
	tradeInUrl: {
		type: String,
		default: "/mobiltelefoner/innbytte/bestill/",
	},
});

const { phoneData, tradeInItem, isSwap, steps, gradingData, removeTradeInItem } = useTradeIn();

const tradeInBonus = computed(
	() =>
		(steps?.value?.wantsToUseInsurance
			? tradeInItem.value?.metaData?.gradingData?.campaignBonus?.estimatedValue?.withClaim
			: tradeInItem.value?.metaData?.gradingData?.campaignBonus?.estimatedValue?.withoutClaim) ?? 0,
);

const estimate = computed(() => {
	return steps?.value?.wantsToUseInsurance || steps?.value?.goToSummary
		? formatPrice.oneTime(
				(steps?.value?.wantsToUseInsurance
					? gradingData?.value?.estimatedValue?.withClaim
					: gradingData?.value?.estimatedValue?.withoutClaim) + tradeInBonus.value,
			)
		: undefined;
});

const dynamicContentFunction = computed(() => {
	return dynamicContent({ estimate: estimate.value });
});

const filteredTexts = computed(() => {
	return props.texts.filter((text) => {
		if (isSwap.value) {
			return text.type === "SWAP";
		} else if (tradeInBonus.value !== 0) {
			return text.type === "TRADE_IN_WITH_BONUS";
		} else if (tradeInItem.value) {
			return text.type === "TRADE_IN";
		} else {
			return text.type === "SELECT";
		}
	});
});

const heading = computed(() => {
	return estimate?.value || filteredTexts.value.some((text) => text.type === "SELECT")
		? dynamicContentFunction.value(filteredTexts.value.find((text) => text.textType === "HEADING")?.text)
		: undefined;
});

const subHeading = computed(() => {
	return estimate?.value || filteredTexts.value.some((text) => text.type === "SELECT")
		? dynamicContentFunction.value(filteredTexts.value.find((text) => text.textType === "SUB_HEADING")?.text)
		: undefined;
});

const badge = computed(() => {
	return dynamicContentFunction.value(filteredTexts.value.find((text) => text.textType === "BADGE_TEXT")?.text);
});

const button = computed(() => {
	return dynamicContentFunction.value(filteredTexts.value.find((text) => text.textType === "BUTTON_TEXT")?.text);
});
</script>

<style scoped lang="scss"></style>
