<template>
	<TnCard
		class="card"
		border
	>
		<template
			v-if="badge"
			#badge
		>
			<TnBadge category="offer">
				{{ badge }}
			</TnBadge>
		</template>

		<div class="card__content">
			<div class="card__content__phone">
				<div class="card__content__phone__info">
					<NuxtImg
						v-if="imgSrc"
						class="card__content__phone__info__img"
						:src="imgSrc"
					/>

					<TnProductIcon
						v-else
						name="FallbackPhonePlan"
					/>

					<div class="card__content__phone__info__text">
						<TnParagraph
							bold
							responsive
							size="l"
						>
							{{ phoneData?.name }}
						</TnParagraph>

						<TnParagraph
							responsive
							size="m"
							color="gray"
						>
							{{ phoneData?.color ?? "" }}{{ phoneData?.color ? ", " : "" }}{{ phoneData?.memorySize ?? "" }}
						</TnParagraph>
					</div>
				</div>

				<div class="card__content__phone__button">
					<TnButton
						v-if="button"
						tertiary
						icon-right="Trashcan"
						size="xs"
						@click="$emit('remove')"
					>
						{{ button }}
					</TnButton>
				</div>
			</div>

			<TnParagraph
				v-if="heading"
				responsive
				size="m"
			>
				{{ heading }}
			</TnParagraph>

			<TnParagraph
				v-if="subHeading"
				responsive
				size="m"
			>
				{{ subHeading }}
			</TnParagraph>
		</div>
	</TnCard>
</template>

<script setup lang="ts">
import type { TradeInPhoneData } from "~/components/hardware-and-insurance/types/TradeInPhoneData";

const props = defineProps({
	badge: {
		type: String,
		required: true,
	},
	heading: {
		type: String,
		required: true,
	},
	subHeading: {
		type: String,
		required: true,
	},
	button: {
		type: String,
		required: true,
	},
	phoneData: {
		type: Object as PropType<TradeInPhoneData>,
		required: true,
	},
});

defineEmits(["remove"]);

const imgSrc = computed(() => props.phoneData?.imageUrl);
</script>

<style scoped lang="scss">
.card {
	padding: $spacing-2xl $spacing-m $spacing-m;

	&__content {
		display: flex;
		flex-direction: column;
		gap: $spacing-m;
		width: 100%;

		&__phone {
			display: flex;
			flex-direction: row;
			gap: $spacing-s;
			align-items: center;
			justify-content: space-between;

			&__info {
				display: flex;
				flex-direction: row;
				gap: $spacing-s;
				align-items: center;

				&__img {
					min-height: 60px;
					max-height: 100px;
				}

				&__text {
					height: 100%;
					display: flex;
					flex-direction: column;
					gap: $spacing-xs;
				}
			}

			&__button {
				display: flex;
				flex-direction: column;
				gap: $spacing-xs;
			}
		}
	}
}
</style>
